import React, { FC, useEffect, useState, useRef } from 'react';
import ListItem from '../components/ListItem';
import ReviewItem from '../components/ReviewItem';
import SimplePoster from '../components/simple_poster';
import { GET_ALL_REVIEWS_MOVIES } from '../redux/action/apiConstants';
import { GET_ALL_REVIEWS_TV } from '../redux/action/apiConstants';
import {InfinitySpin } from 'react-loader-spinner';




type Props = {
    detailData?: any,
    fetchDetail?: any
}

const Review: FC<Props> = ({ detailData, fetchDetail }) => {

    const [products, setProducts] = useState([])
    const [tvreviews, setReviews] = useState([])

    useEffect(() => {

        // fetch(`http://reelmartiniadmin.sapphiresolutions.in.net/get_letest_review`)
        fetch(`${GET_ALL_REVIEWS_MOVIES}`)
            .then(res => res.json())
            .then(datas => {

                setProducts(datas.data)
                //  console.log(datas.data);
            })
    },[]);

    useEffect(() => {

        // fetch(`http://reelmartiniadmin.sapphiresolutions.in.net/get_tv_letest_review`)
        fetch(`${GET_ALL_REVIEWS_TV}`)
            .then(res => res.json())
            .then(datas => {
                setReviews(datas.data)
                //  console.log(datas.data);
            })
    },[]);
    const [showLoader, setShowLoader] = useState(true);
    const contentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (contentRef.current) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
      }
    }, [products]);
    useEffect(() => {
        const timer = setTimeout(() => {
          setShowLoader(false);
        }, 200); // Set an arbitrary timeout value
        
        return () => {
          clearTimeout(timer);
        };
      }, [products]);


    return (
        <>
            {/* {console.log("html e"+product)} */}

            <section className="movie-slide5 movie-slide-mobile mt-5">
            {showLoader ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '65vh' }}>
          <InfinitySpin  color="#ea4df6"/>
          </div>
              ) : (
                
                <div className="container">

                {products.length > 0 ? (
                    <>
                    <div className="row">
                        <div className="col-md-6  col-7  movie-headings">
                            <h2 className='text-white font-weight-bold'> Reviews</h2>
                        </div>
                    </div>

                    <div className="row">
                        {
                            products && products.length > 0 &&
                            products.map((item: any, index: number) => {
                                // console.log(item.data[0].author_details.username);
                                // let imgUrl = item.author_details.avatar_path.includes('http') ? item.author_details.avatar_path.substring(1) : `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.author_details.avatar_path}`;
                                const review_data = item.data.map((revirw: any, i: number) => {
                                    // console.log(revirw.id)
                                    let imgUrl = `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${revirw.author_details.avatar_path}`;
                                    return (
                                        <div className="col-lg-6 col-md-6 col-12 pl-lg-0" key={`rewiew-${revirw.id}`}>
                                            <div className="inner-padding">
                                                <ReviewItem profileImage={imgUrl} name={revirw.author} username={revirw.author_details.username} description={revirw.content} date={revirw.created_at} />
                                            </div>
                                        </div>
                                    )


                                })
                                // let imgUrl =  `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.data[0].author_details.avatar_path}`;
                                //    let imgUrl = ''
                                return (
                                    <div className="row mb-5" >
                                        
                                        <div
                                            // className={`customcol item`}
                                            key={`list-item-${index}`}
                                        style={{ width: '126.1px',height: 'auto' }}
                                        >
                                            <div className={'item'}>
                                            <SimplePoster
                                                mainId={item.id}
                                                poster={`${process.env.REACT_APP_IMDB_IMAGE_URL}${item.poster_path}`}
                                                name={item.title}
                                                id={'Movie'}
                                            // description={item.release_date}
                                            />
                                            </div>
                                        </div>
                                        <h2 className="fs-20 section-name ml-3 mt-5 pt-5 text-white">{item.title}</h2>
                                        <div className="col-md-6  col-7  movie-headings"></div>
                                        {review_data}
                                    </div>

                                )
                            })
                        }
                    </div>

                    <div className="row">
                        {
                            tvreviews && tvreviews.length > 0 &&
                            tvreviews.map((item: any, index: number) => {
                                // console.log(item.data[0].author_details.username);
                                // let imgUrl = item.author_details.avatar_path.includes('http') ? item.author_details.avatar_path.substring(1) : `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.author_details.avatar_path}`;
                                const review_data = item.data.map((revirw: any, i: number) => {
                                    // console.log(revirw.id)
                                    let imgUrl = `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${revirw.author_details.avatar_path}`;
                                    return (
                                        <div className="col-lg-6 col-md-6 col-12 pl-lg-0" key={`rewiew-${revirw.id}`}>
                                            <div className="inner-padding">
                                                <ReviewItem profileImage={imgUrl} name={revirw.author} username={revirw.author_details.username} description={revirw.content} date={revirw.created_at} />
                                            </div>
                                        </div>
                                    )


                                })
                                // let imgUrl =  `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.data[0].author_details.avatar_path}`;
                                //    let imgUrl = ''
                                return (
                                    <div className="row mb-5" >
                                    <div
                                        className={`customcol item`}
                                        key={`list-item-${index}`}
                                    // style={{ width: '156.1px'}}
                                    >
                                        <SimplePoster
                                            mainId={item.id}
                                            poster={`${process.env.REACT_APP_IMDB_IMAGE_URL}${item.poster_path}`}
                                            name={item.title}
                                            id={'Tv'}
                                        // description={item.release_date}
                                        />

                                    </div>
                                    <h2 className="fs-20 section-name mt-5 pt-5 text-white">{item.title}</h2>
                                    <div className="col-md-6  col-7  movie-headings"></div>
                                    {review_data}
                                </div>

                                )
                            })
                        }
                    </div>
                    </>
                    ) : (
                        <div className="nodata"  style={{ height: '58vh' }}>
                          <br />
                          <br />
                          <br />
                          <br />
                          <p>No Data Avalible</p>
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      )}
                </div>



                     )}
            </section>
        </>
    )
}
export default (Review);
