import React from 'react';

import './PopupShareModal.css'; // Import the CSS file or use inline styles
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";

const PopupShareModal = () => {
    const discount = window.location.href;
        const title = "Check out this cool website!";
  const handleViewModal = () => {
    const popup = document.querySelector('.popup');
    popup.classList.toggle('show');
  };

  const handleCloseModal = () => {
    const viewBtn = document.querySelector('.social-media-reel');
    viewBtn.click();
  };

  const handleCopyLink = () => {
    const input = document.querySelector('.field input');
    input.select();
    if (document.execCommand('copy')) {
      const field = document.querySelector('.field');
      const copy = document.querySelector('.field button');

      field.classList.add('active');
      copy.innerText = 'Copied';

      setTimeout(() => {
        window.getSelection().removeAllRanges();
        field.classList.remove('active');
        copy.innerText = 'Copy';
      }, 3000);
    }
  };

  return (
    <>
       <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
        />
        <link
          rel="stylesheet"
          href="https://unicons.iconscout.com/release/v3.0.6/css/line.css"
        />

      <a href="javascript:void(0);"  onClick={handleViewModal} className="social-media-reel"><i className="fa fa-share-alt"></i></a>

      <div className="popup">
        <header>
          <span>Reelmartini</span>
          <div className="close" onClick={handleCloseModal}>
            <i className="uil uil-times"></i>
          </div>
        </header>
        <div className="content ">
          <p>Share this link via</p>
          <ul className="icons p-0">
          <a href="javascript:void(0);">
          <FacebookShareButton url={discount} quote={title}>
              <i className="fab fa-facebook-f"></i>
              </FacebookShareButton>
            </a>
            <a href="javascript:void(0);">
                <TwitterShareButton url={discount} quote={title} >
              <i className="fab fa-twitter"></i>
              </TwitterShareButton>
            </a>
            <a href="javascript:void(0);">
                <RedditShareButton url={discount} quote={title}>
              <i className="fab fa-reddit"></i>
              </RedditShareButton>
            </a>
            <a href="javascript:void(0);">
            <WhatsappShareButton url={discount} title={title} >
              <i className="fab fa-whatsapp"></i>
              </WhatsappShareButton>
            </a>
            <a hhref="javascript:void(0);">
            <TelegramShareButton url={discount} title={title}>
              <i className="fab fa-telegram-plane"></i>
              </TelegramShareButton>
            </a>
            <a href="javascript:void(0);">
            <EmailShareButton url={discount} subject={title} >
              <i className="fab fa-google"></i>
              </EmailShareButton>
            </a>
          </ul>
          <p>Or copy link</p>
          <div className="field">
            {/* <i className="url-icon uil uil-link"></i> */}
            <input type="text" readOnly value={discount}/>
            <button onClick={handleCopyLink}>Copy</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupShareModal;
