import React, { FC, useEffect, useState,useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContentSlider from "../components/ContentSlider"
import ReviewItem from '../components/ReviewItem';
import { fetchTvDetail } from '../redux/action/TvDetailAction';
import logo from './movie.jpg'
import Modal from '../components/tvdetail_model';
import { Tooltip } from "react-bootstrap";
import { GET_TV_WATCH_DETAIL } from '../redux/action/apiConstants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popup from 'reactjs-popup';
import Share from '../components/share_old';
import PopupShareModal from '../components/share';
import SkeletonDetails from '../components/Skeletondetail';

type Props = {
    TvdetailData?: any,
    fetchTvDetail?: any
}


interface WebsiteData {
    type: string;
    quality: string;
    addOn: string;
    link: string;
    watchLink: string;
    audios: AudioData[];
    subtitles: SubtitleData[];
    price: null | PriceData;
    leaving: number;
    availableSince: number;
}

interface AudioData {
    language: string;
    region: string;
}

interface SubtitleData {
    locale: LocaleData;
    closedCaptions: boolean;
}

interface LocaleData {
    language: string;
    region: string;
}

interface PriceData {
    amount: string;
    currency: string;
    formatted: string;
}

interface Websites {
    us: {
        disney: WebsiteData[];
        prime: WebsiteData[];
    };
}


const TvDetail: FC<Props> = ({ TvdetailData, fetchTvDetail }) => {

    let { id } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const textAreaRef = useRef(null);
    const [IsLoading, setIsLoading] = useState(true);
    async function copyToClip() {
        await navigator.clipboard.writeText(window.location.href);
        setCopySuccess("Copied");
    }
    const data = [
        {
            poster: './assets/images/Reel-Martini/img_4.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_5.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_6.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_7.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_8.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_9.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_10.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_11.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_12.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_13.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_14.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
    ]
    const [copied, setCopied] = useState(false);
    var actorsArray: any = [];
    var screenplayArray: any = [];
    var directorArray: any = [];
    var watchData: any = [];
    const [websites, setWebsites] = useState<Websites | null>(null);
    const discount = window.location.href;
    const [Watch, setWatch] = useState([])
    const [tryId, setTryId] = useState(0)
    const [products, setProducts] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            const script = document.createElement('script');
            script.src = "/assets/js/developer.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            }
        }, 1000);
    }, [TvdetailData]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        fetchTvDetail(id)// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    console.log(TvdetailData)
    // useEffect(() => {
    //     fetch(`http://reelmartiniadmin.sapphiresolutions.in.net/get-videodetails/` + TvdetailData.id)
    //         .then(res => res.text())
    //         .then(datas => {
    //             console.log(datas);
    //             //   setProduct(datas)

    //         })
    // },[]);
    useEffect(() => {
        fetch(`${GET_TV_WATCH_DETAIL}/${id}`)
            .then(res => res.json())
            .then(datas => {
                // console.log(datas);
                setWebsites(datas.data)
                setIsLoading(false);
            })
    },[]);
    if (Watch.length > 0) {
        watchData = Watch;
    }
    
    const showMore = () => {
        setProducts(1);
    }
    const lessMore = () => {
        // alert()
        // scollToRef.current.scrollIntoView()accordion-accordion
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
        setProducts(0);
    }



    const renderWatchOptions = (sites: WebsiteData[], platform:any) => {
        if (!Array.isArray(sites)) {
          return null;
        }
        
        const platformConnector = '_';
        const platformImagesPath = window.location.origin + '/assets/images/Streaming_Platform_Logos/';
        const imageUrl = `${platformImagesPath}${platform}.jpg`;
        return sites.map((site, index) => (
            <div className="col-md-12 col-lg-4"  key={index}>
            <h4 className="movie-overview">Where to {site.type === 'subscription' ? 'Watch' : 'Rent/Buy'}</h4>
            <div className="movie-border row">
          <div className="col-md-6 col-6">
            <a href={site.link} target="_blank" rel="noopener noreferrer">
            <img src={imageUrl} alt={platform}  className='img-fluid'/>
            </a>
                {/* <p>{platform}</p> */}
          </div>
          <div className="col-md-6 col-6 my-auto">
              <p className="movie-rent mb-2">{site.type === 'subscription' ? 'Streaming ' : site.type === 'addon' ? 'Addon': 'Rent/Buy'}</p>
              <p className="movie-rent">
                {site.type === 'subscription' ? site.quality : site.price ? `$ ${site.price.amount} ${site.quality}` : site.quality}
              </p>
            </div>
          </div>
    </div>
        ));
      };

      const renderWatchSection = () => {
        if (!websites || !websites.us) {
          return null;
        }
        return Object.entries(websites.us).map(([platform, sites]) => (
                <>
                {renderWatchOptions(sites, platform)}
                </>
          ));
        };

    // console.log(TvdetailData.similar);

    return (
        <>
            {modalOpen && <Modal setOpenModal={setModalOpen} id={tryId}></Modal>}
            {(IsLoading) ? <SkeletonDetails /> : <>
            <section className="mx-4">
                <div className="container review-section ">
                    <div className="section-padding">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 pl-lg-1">
                                <div className="movie-review-image">
                                    {TvdetailData.poster_path == null ? <img src={logo} alt="" className='w-100' /> :
                                        <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${TvdetailData.poster_path}`} alt="" className='w-100' />}
                                    {/* <p>{`${process.env.REACT_APP_IMDB_IMAGE_URL}${TvdetailData.poster_path}`}</p> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-8 my-auto">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <h2 className="movie-name-review">{TvdetailData && TvdetailData.name}</h2>
                                    </div>
                                    <div className="col-md-12 col-lg-6 col-12">
                                        {/* <p className="movie-year">&nbsp;2022 - English - Action - 2h 11m</p> */}
                                    </div>
                                    <div className="col-md-12 col-lg-6 col-12">
                                        <p className="movie-realsedate">&nbsp;Release Date: <span>{TvdetailData && TvdetailData.first_air_date}</span>
                                        </p>
                                    </div>
                                    <div className="col-md-12 col-lg-5 col-12">
                                        {/* <div className="footer-menu my-2 my-lg-0"> */}
                                        <PopupShareModal/> 
                                            {/* <a href="#" className="social-media-reel"><i className="fa fa-plus ml-0"></i></a>
                                            <a href="#" className="social-media-reel"><i className="fa fa-eye"></i></a> */}
                                            {/* <a href="#" className="social-media-reel"><i className="fa fa-thumbs-up"></i></a> */}
                                            {/* <a href="#" className="social-media-reel"><i className="fa fa-thumbs-down"></i></a> */}
                                               {/* <Popup trigger={<span><CopyToClipboard text={discount} onCopy={() => setCopied(true)}>
                                           <a href="javascript:void(0);" className="social-media-reel"><i className="fa fa-share-alt"></i></a> 
                                                    </CopyToClipboard></span>} position="right top"> <div className='text-white'>Copied</div> </Popup> */}
                                                    
                                        {/* </div> */}
                                    </div>
                                    <div className="col-md-12 col-lg-4 col-12">
                                        <p className="movie-realsedate text-white float-left mt-2">&nbsp;Number of seasons : <span>{TvdetailData && TvdetailData.number_of_seasons}</span>
                                        </p>
                                    </div>
                                    <div className="col-md-12 col-lg-3 col-12 my-auto">
                                        <a href="javascript:void(0);" onClick={() => { setModalOpen(true); setTryId(TvdetailData.id) }}>
                                            <p className="movie-watchtrailer"><i className="fa fa-play"></i> &nbsp;Watch Trailer</p>
                                        </a>
                                    </div>
                                    
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="movie-overview">Overview</h4>
                                        <p className="movie-overview-2">{TvdetailData && TvdetailData.overview}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <h4 className="movie-overview">Actor</h4>
                                        {
                                            TvdetailData && TvdetailData.credits && TvdetailData.credits.cast.map((cast: any, index: number) => {
                                                // actorsArray.push(cast.name);
                                                if (products == 0) {
                                                    if (index < 5) {
                                                        actorsArray.push(cast.name);
                                                    }
                                                } else {
                                                    actorsArray.push(cast.name);
                                                }
                                            })
                                        }
                                        <p className="movie-overview-2">{actorsArray.join(',')}</p>
                                        {(() => {
                                           if('credits' in TvdetailData){
                                            if(TvdetailData.credits.cast){
                                            if (products == 0) {
                                                return (
                                                    <h4 className="movie-overview"><a href="javascript:void(0);" onClick={() => { showMore() }} className='sectional-heading fs-12'>  Read more</a></h4>
                                                )
                                            } else {
                                                return (
                                                    <h4 className="movie-overview"><a href="javascript:void(0);" onClick={() => { lessMore() }} className='sectional-heading fs-12'>  Read Less</a></h4>
                                                )
                                            }
                                        }
                                          }
                                        })()}

                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <h4 className="movie-overview">Screenplay</h4>
                                        {
                                            TvdetailData && TvdetailData.credits && TvdetailData.credits.crew.filter((crew: any) => crew.known_for_department === 'Writing').map((cast: any, index: number) => {
                                                screenplayArray.push(cast.name);
                                            })
                                        }
                                        <p className="movie-overview-2">{screenplayArray.join(',')}</p>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <h4 className="movie-overview">Director</h4>
                                        {
                                            TvdetailData && TvdetailData.credits && TvdetailData.credits.crew.filter((crew: any) => crew.known_for_department === 'Directing').map((cast: any, index: number) => {
                                                directorArray.push(cast.name);
                                            })
                                        }
                                        <p className="movie-overview-2">{directorArray.join(',')}</p>
                                    
                                    </div>

                                    {renderWatchSection()}
                                    {/* {(() => {

                                        if (watchData.length > 0 && watchData[0] != null) {
                                            if ('flatrate' in watchData[0]) {
                                                return (
                                                    <div className="col-md-12 col-lg-4">
                                                        <h4 className="movie-overview">Where to Watch</h4>
                                                        <div className="movie-border row">
                                                            <div className="col-md-6 col-6">
                                                                <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].flatrate[0].logo_path}`} alt="" className='w-100' />
                                                            </div>
                                                            <div className="col-md-6 col-6 my-auto">
                                                                <p className="movie-rent mb-2">{watchData[0].flatrate[0].provider_name}</p>
                                                                <p className="movie-rent">Streaming Now</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }else if('ads' in watchData[0]){
                                                return (
                                                    <div className="col-md-12 col-lg-4">
                                                        <h4 className="movie-overview">Where to Watch</h4>
                                                        <div className="movie-border row">
                                                            <div className="col-md-6 col-6">
                                                                <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].ads[0].logo_path}`} alt="" className='w-100' />
                                                            </div>
                                                            <div className="col-md-6 col-6 my-auto">
                                                                <p className="movie-rent mb-2">{watchData[0].ads[0].provider_name}</p>
                                                                <p className="movie-rent">Streaming Now</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        }


                                    })()}
                                    {(() => {
                                        if (watchData.length > 0 && watchData[0] != null) {
                                            if ('buy' in watchData[0]) {
                                                return (
                                                    <div className="col-md-12 col-lg-4">
                                                        <h4 className="movie-overview">Buy</h4>
                                                        <div className="movie-border row">
                                                            <div className="col-md-5 col-5">
                                                                <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].buy[0].logo_path}`} alt="" className='w-100' />
                                                            </div>
                                                            <div className="col-md-7 col-7 my-auto">
                                                                <p className="movie-rent mb-2">{watchData[0].buy[0].provider_name}</p>
                                                                <p className="movie-rent">Buy Now</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else if ('rent' in watchData[0]) {
                                                return (
                                                    <div className="col-md-12 col-lg-4">
                                                        <h4 className="movie-overview">Rent</h4>
                                                        <div className="movie-border row">
                                                            <div className="col-md-5 col-5">
                                                                <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].rent[0].logo_path}`} alt="" className='w-100' />
                                                            </div>
                                                            <div className="col-md-7 col-7 my-auto">
                                                                <p className="movie-rent mb-2">{watchData[0].rent[0].provider_name}</p>
                                                                <p className="movie-rent">Buy Now</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        }
                                    })()} */}
                                    {/* <div className="col-md-12 col-lg-4">
                                        <h4 className="movie-overview">Where to Watch</h4>
                                        <div className="movie-border row">
                                            <div className="col-md-6 col-6">
                                                <img src="/assets/images/Reel-Martini/img_detail_3.png" alt="" />
                                            </div>
                                            <div className="col-md-6 col-6 my-auto">
                                                <p className="movie-rent mb-2">Rent</p>
                                                <p className="movie-rent">$ 2.99 HD</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 col-lg-4">
                                        <h4 className="movie-overview">Rating</h4>
                                        <div className="movie-border row">
                                            <div className="col-md-6 col-6">
                                                <img src="/assets/images/Reel-Martini/Img_IMDB.png" alt="" />
                                            </div>
                                            <div className="col-md-6 col-6 my-auto">
                                                <p className="movie-rent mb-2">Rating</p>
                                                <p className="movie-rent"><span>{TvdetailData.vote_average}</span>/10</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12 col-lg-4">
                                        <h4 className="movie-overview">Rating</h4>
                                        <div className="movie-border row">
                                            <div className="col-md-6 col-6">
                                                <img src="/assets/images/Reel-Martini/img_rotten.png" alt="" />
                                            </div>
                                            <div className="col-md-6 col-6 my-auto">
                                                <p className="movie-rent mb-2">Rating</p>
                                                <p className="movie-rent"><span>5.7</span>/10</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>.

            {(() => {
                if('reviews' in TvdetailData){
                    if(TvdetailData.reviews.results.length > 0){
                    return (
                        <section className="movie-slide5 movie-slide-mobile mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6  col-7  movie-headings">
                                    <h2 className="sectional-heading fs-20 section-name">Reviews</h2>
                                </div>
                                <div className="col-md-6  col-5  movie-headings">
                                    {/* <a href="#" className="sectional-heading fs-20 view-btn">View All <i className="fa fa-angle-right"
                                        aria-hidden="true"></i> </a> */}
                                </div>
                            </div>
                            <div className="row">
                                {
                                    TvdetailData && TvdetailData.reviews && TvdetailData.reviews.results.length > 0 &&
                                    TvdetailData.reviews.results.map((item: any, index: number) => {
                                        let imgUrl = `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.author_details.avatar_path}`;
                                        return (
                                            <div className="col-lg-6 col-md-6 col-12 pl-lg-0" key={`rewiew-${item.id}`}>
                                                <div className="inner-padding">
                                                    <ReviewItem profileImage={imgUrl} name={item.author} username={item.author_details.username} description={item.content} date={item.created_at} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                    )
                            }
                }

            })()}
            {/* <section className="movie-slide5 movie-slide-mobile mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  col-7  movie-headings">
                            <h2 className="sectional-heading fs-20 section-name">Reviews</h2>
                        </div>
                        <div className="col-md-6  col-5  movie-headings">
                            <a href="#" className="sectional-heading fs-20 view-btn">View All <i className="fa fa-angle-right"
                                aria-hidden="true"></i> </a>
                        </div>
                    </div>
                    <div className="row">
                        {
                            TvdetailData && TvdetailData.reviews && TvdetailData.reviews.results.length > 0 &&
                            TvdetailData.reviews.results.map((item: any, index: number) => {
                                let imgUrl = `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.author_details.avatar_path}`;
                                return (
                                    <div className="col-lg-6 col-md-6 col-12 pl-lg-0" key={`rewiew-${item.id}`}>
                                        <div className="inner-padding">
                                            <ReviewItem profileImage={imgUrl} name={item.author} username={item.author_details.username} description={item.content} date={item.created_at} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section> */}

            {(() => {
                if('similar' in TvdetailData){
                    if(TvdetailData.similar.total_results > 0){
                        return (
                            <section className="movie-slide1 movie-slide-mobile">
                            <div className="container-fluid container-padding2">
                                <div className="row">
                                    
                                    <ContentSlider title="Similar Movies" data={TvdetailData && TvdetailData.similar && TvdetailData.similar.results} />
                                </div>
                            </div>
                        </section>
                           );
                    } else{
                        return (
                            <section className="movie-slide1 movie-slide-mobile">
                            <div className="container-fluid p-2 container-padding2">
                                </div>
                                </section>
                        )
                }
                }
            })()}
          </>}
        </>
    )
}
const mapStateToProps = (state: any) => {
    return {
        TvdetailData: state.tvdetailReducer.TvdetailData,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchTvDetail: (id: any) => dispatch(fetchTvDetail(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TvDetail);
