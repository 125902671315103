import React, { FC, useEffect, useState } from 'react';
import "./Modal.css";
import { ColorRing } from 'react-loader-spinner'

type Props = {
  setOpenModal?: any,
  id?: any,
}

  const Modal:FC<Props> = ({setOpenModal, id}) => {
    console.log(id);
    const [product,setProduct]= useState('')
    useEffect(() => {
      fetch(`https://reelmartiniliveapi.sapphiresolutions.in.net/api/get-videodetails/`+id)
      .then(res => res.text())
      .then(datas => {
          console.log(datas);
        setProduct(datas)
       
      })
  },[]);
  
    return (
      <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          {/* <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button> */}

          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
           {/*<ColorRing
            visible={true}
            height="130"
            width="130"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
          />*/}
          X
          </button>

        </div>
        <div className="body">
            <div dangerouslySetInnerHTML={{ __html: product }}></div>
        </div>
      </div>
    </div>
    )
}

export default Modal  