import { MOVIE_LIST_CONTENT, MOVIE_LIST_LOAD_MORE } from "../constants";
const intialState = {
    listData : [],
    totalPage: 1,
    page: 1
};
export const listReducer = (state = intialState, action: any) => {
    switch (action.type) {
      case MOVIE_LIST_CONTENT:
        if(action.data.length > 1) {
          var pagevalue = action.data[2].total_pages;
        }else{
          var pagevalue = action.data[0].total_pages;
        }
        return {
          ...state,
          page: 3,
          totalPage: pagevalue,
          listData: action.data
        };;
      case MOVIE_LIST_LOAD_MORE:
        var valur = action.data[0].page;
        return {
            ...state,
            page: valur,
            listData:[...state.listData, ...action.data]
        };;
      default:
        return state;
    }
  };