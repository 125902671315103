import React from 'react'
import ContentLoader from 'react-content-loader';

const MyComponent = (props:any) => {
  return (

    <ContentLoader 
    height={props.height}
    width={props.width}
    speed={3}
    backgroundColor={'#666'}
    foregroundColor={'#CCC'}
   >
         <rect x="0" y="0" rx="10" ry="10" width='100%' height='100%' />
    </ContentLoader>

  )
}

export default MyComponent