import React, { FC, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContentSlider from "../components/ContentSlider"
import ListItem from '../components/ListItem';
import MainSlider from '../components/MainSlider';
import ReviewItem from '../components/ReviewItem';
import { fetchDetail } from '../redux/action/detailAction';
import logo from './movie.jpg';
import Modal from '../components/Modal';
import { GET_MOVILE_WATCH_DETAIL } from '../redux/action/apiConstants';
import { watch } from 'fs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popup from 'reactjs-popup';
import Share from '../components/share_old';
import PopupShareModal from '../components/share';
import SkeletonDetails from '../components/Skeletondetail';

type Props = {
    detailData?: any,
    fetchDetail?: any
}


interface WebsiteData {
    type: string;
    quality: string;
    addOn: string;
    link: string;
    watchLink: string;
    audios: AudioData[];
    subtitles: SubtitleData[];
    price: null | PriceData;
    leaving: number;
    availableSince: number;
}

interface AudioData {
    language: string;
    region: string;
}

interface SubtitleData {
    locale: LocaleData;
    closedCaptions: boolean;
}

interface LocaleData {
    language: string;
    region: string;
}

interface PriceData {
    amount: string;
    currency: string;
    formatted: string;
}

interface Websites {
    us: {
        disney: WebsiteData[];
        prime: WebsiteData[];
    };
}




const Detail: FC<Props> = ({ detailData, fetchDetail }) => {
    const [modalOpen, setModalOpen] = useState(false);
    let { id } = useParams();
    const data = [
        {
            poster: './assets/images/Reel-Martini/img_4.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_5.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_6.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_7.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_8.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_9.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_10.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_11.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_12.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_13.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
        {
            poster: './assets/images/Reel-Martini/img_14.png',
            name: 'Movie Name',
            description: '2022 -Hindi - Mythology'
        },
    ]
    const scollToRef = useRef();
    var actorsArray: any = [];
    var watchData: any = [];
    var web: any = [];
    var screenplayArray: any = [];
    var directorArray: any = [];
    const [products, setProducts] = useState(0)
    const [Watch, setWatch] = useState([]);
    const [websites, setWebsites] = useState<Websites | null>(null);
    const [tryId, setTryId] = useState(0)
    const [IsLoading, setIsLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState("");
    const textAreaRef = useRef(null);
    const [copied, setCopied] = useState(false);
    async function copyToClip() {
        await navigator.clipboard.writeText(window.location.href);
        setCopySuccess("Copied");
    }
    useEffect(() => {
        setTimeout(() => {
            const script = document.createElement('script');
            script.src = "/assets/js/developer.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            }
        }, 1000);
    });
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //   },
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        fetchDetail(id)// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])



    // console.log(watchData);

    const showMore = () => {
        setProducts(1);
    }
    const lessMore = () => {
        // alert()
        // scollToRef.current.scrollIntoView()accordion-accordion
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
        setProducts(0);
    }
    useEffect(() => {
        // componentDidMount();
        fetch(`${GET_MOVILE_WATCH_DETAIL}/${id}`)
            .then(res => res.json())
            .then(datas => {
                // console.log(datas);
                setWebsites(datas.websites);
                // setWatch(datas.data)
                setIsLoading(false);
            })
    }, []);
    const discount = window.location.href;
    // console.log(detailData);
    // const scrollToTop = () =>{
    //     window.scrollTo({
    //       top: 0, 
    //       behavior: 'smooth'
    //       /* you can also use 'auto' behaviour
    //          in place of 'smooth' */
    //     });
    //   };
    // console.log(Watch);

    // console.log(website);

    const renderWatchOptions = (sites: WebsiteData[], platform: any) => {
        if (!Array.isArray(sites)) {
            return null;
        }

        const platformConnector = '_';
        const platformImagesPath = window.location.origin + '/assets/images/Streaming_Platform_Logos/';
        const imageUrl = `${platformImagesPath}${platform}.jpg`;
        return sites.map((site, index) => (
            <div className="col-md-12 col-lg-4" key={index}>
                <h4 className="movie-overview">Where to {site.type === 'subscription' ? 'Watch' : 'Rent/Buy'}</h4>
                <div className="movie-border row">
                    <div className="col-md-6 col-6">
                        <a href={site.link} target="_blank" rel="noopener noreferrer">
                            <img src={imageUrl} alt={platform} className='img-fluid' />
                        </a>
                        {/* <p>{platform}</p> */}
                    </div>
                    <div className="col-md-6 col-6 my-auto">
                        <p className="movie-rent mb-2">{site.type === 'subscription' ? 'Streaming ' : site.type === 'addon' ? 'Addon' : 'Rent/Buy'}</p>
                        <p className="movie-rent">
                            {site.type === 'subscription' ? site.quality : site.price ? `$ ${site.price.amount} ${site.quality}` : site.quality}
                        </p>
                    </div>
                </div>
            </div>
        ));
    };



    ///////////////////////////// this code for only specific quality display data ///////////////////////

    // const renderWatchOptions = (sites: WebsiteData[], platform: any) => {
    //     if (!Array.isArray(sites)) {
    //       return null;
    //     }

    //     const platformConnector = '_';
    //     const platformImagesPath = window.location.origin + '/assets/images/Streaming_Platform_Logos/';
    //     const imageUrl = `${platformImagesPath}${platform}.jpg`;

    //     const sdSites = sites.filter(site => site.quality === 'hd');

    //     return sdSites.map((site, index) => (
    //       <div className="col-md-12 col-lg-4" key={index}>
    //         <h4 className="movie-overview">Where to {site.type === 'subscription' ? 'Watch' : 'Rent/Buy'}</h4>
    //         <div className="movie-border row">
    //           <div className="col-md-6 col-6">
    //             <a href={site.link} target="_blank" rel="noopener noreferrer">
    //               <img src={imageUrl} alt={platform} className='img-fluid' />
    //             </a>
    //           </div>
    //           <div className="col-md-6 col-6 my-auto">
    //             <p className="movie-rent mb-2">{site.type === 'subscription' ? 'Streaming' : site.type === 'addon' ? 'Addon' : 'Rent/Buy'}</p>
    //             <p className="movie-rent">
    //               {site.quality}
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     ));
    //   };

    const renderWatchSection = () => {
        if (!websites || !websites.us) {
            return null;
        }
        return Object.entries(websites.us).map(([platform, sites]) => (
            <>
                {renderWatchOptions(sites, platform)}
            </>
        ));
    };


    return (
        <>
            {modalOpen && <Modal setOpenModal={setModalOpen} id={tryId}></Modal>}

            {(IsLoading) ? <SkeletonDetails /> : <>
                {/* {console.log("html e"+product)} */}
                <section className="mx-4">

                    <div className="container review-section ">
                        <div className="section-padding">
                            <div className="row">
                                <div className="col-md-6 col-lg-4 pl-lg-1">
                                    <div className="movie-review-image">
                                        {detailData.poster_path == null ? <img src={logo} alt="" className='w-100' /> :
                                            <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${detailData.poster_path}`} alt="" className='w-100' />}
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-8 my-auto">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <h2 className="movie-name-review">{detailData && detailData.title}</h2>
                                        </div>
                                        <div className="col-md-12 col-lg-6 col-12">
                                            {/* <p className="movie-year">&nbsp;2022 - English - Action - 2h 11m</p> */}
                                        </div>
                                        <div className="col-md-12 col-lg-6 col-12">
                                            <p className="movie-realsedate">&nbsp;Release Date: <span>{detailData && detailData.release_date}</span>
                                            </p>
                                        </div>
                                        {/* <div dangerouslySetInnerHTML={{ __html: product }}></div> */}
                                        <div className="col-md-12 col-lg-5 col-12">
                                            {/* <div className="footer-menu my-2 my-lg-0"> */}
                                            {/* <a href="#" className="social-media-reel"><i className="fa fa-plus ml-0"></i></a>
                                            <a href="#" className="social-media-reel"><i className="fa fa-eye"></i></a> */}
                                            {/* <a href="#" className="social-media-reel"><i className="fa fa-thumbs-up"></i></a>
                                            <a href="#" className="social-media-reel"><i className="fa fa-thumbs-down"></i></a> */}
                                            {/* <CopyToClipboard text={discount} onCopy={() => setCopied(true)}>
                                           <a href="javascript:void(0);" className="social-media-reel"><i className="fa fa-share-alt"></i></a> 
                                                    </CopyToClipboard> */}
                                            {/* <Popup trigger={<span><CopyToClipboard text={discount} onCopy={() => setCopied(true)}>
                                           <a href="javascript:void(0);" className="social-media-reel"><i className="fa fa-share-alt"></i></a> 
                                                    </CopyToClipboard></span>} position="right top"> <div className='text-white'>Copied</div> </Popup> */}
                                            <PopupShareModal />
                                            {/* <div className="addthis_inline_share_toolbox" data-url={  `https://www.facebook.com/` } data-title={ `facebook` }> 
                                         
                                            </div>        
                                            <a href="javascript:void(0);" className="social-media-reel" onClick={componentDidMount}><i className="fa fa-share-alt"></i></a>
                                         */}
                                            {/* </div> */}

                                        </div>
                                        <div className="col-md-12 col-lg-6 col-12 my-auto">
                                            <a href="javascript:void(0);" onClick={() => { setModalOpen(true); setTryId(detailData.id) }}>
                                                <p className="movie-watchtrailer"><i className="fa fa-play"></i> &nbsp;Watch Trailer</p>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="movie-overview">Overview</h4>
                                            <p className="movie-overview-2">{detailData && detailData.overview}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <h4 className="movie-overview">Actor</h4>
                                            {
                                                detailData && detailData.credits && detailData.credits.cast.map((cast: any, index: number) => {

                                                    // console.log(index);

                                                    // moreactorsArray.push(cast.name);

                                                    if (products == 0) {
                                                        if (index < 5) {
                                                            actorsArray.push(cast.name);
                                                        }
                                                    } else {
                                                        actorsArray.push(cast.name);
                                                    }
                                                })
                                            }

                                            <p className="movie-overview-2">{actorsArray.join(',')}</p>
                                            {(() => {
                                                if (products == 0) {
                                                    return (
                                                        <h4 className="movie-overview"><a href="javascript:void(0);" onClick={() => { showMore() }} className='sectional-heading fs-12'>  Read more</a></h4>
                                                    )
                                                } else {
                                                    return (
                                                        <h4 className="movie-overview"><a href="javascript:void(0);" onClick={() => { lessMore() }} className='sectional-heading fs-12'>  Read Less</a></h4>
                                                    )
                                                }
                                            })()}



                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <h4 className="movie-overview">Screenplay</h4>
                                            {
                                                detailData && detailData.credits && detailData.credits.crew.filter((crew: any) => crew.known_for_department === 'Writing').map((cast: any, index: number) => {
                                                    screenplayArray.push(cast.name);
                                                })
                                            }
                                            <p className="movie-overview-2">{screenplayArray.join(',')}</p>
                                        </div>
                                        <div className="col-lg-5 col-md-12">
                                            <h4 className="movie-overview">Director</h4>
                                            {
                                                detailData && detailData.credits && detailData.credits.crew.filter((crew: any) => crew.known_for_department === 'Directing').map((cast: any, index: number) => {
                                                    directorArray.push(cast.name);
                                                })
                                            }
                                            <p className="movie-overview-2">{directorArray.join(',')}</p>
                                        </div>
                                        {/* <div className="col-md-12 col-lg-4">
                                        <h4 className="movie-overview">Where to Watch</h4>
                                        <div className="movie-border row"> */}

                                        {renderWatchSection()}


                                        {/* {websites?.us?.prime?.map((site, index) => (
                                                             <div className="col-md-12 col-lg-4">
                                                             <h4 className="movie-overview">Where to Watch</h4>
                                                             <div className="movie-border row">
                                                         <div className="col-md-5 col-6" key={index}>
                                                             <a href={site.link} target="_blank" rel="noopener noreferrer">
                                                                 <img src="/assets/images/Reel-Martini/img_detail_3.png" alt="" />
                                                             </a>
                                                      
                                                         </div>
                                                         <div className="col-md-6 col-6 my-auto">
                                                                 <p className="movie-rent mb-2">Rent</p>
                                                                 <p className="movie-rent">
                                                                     $ {site.price?.amount} {site.price?.currency}
                                                                 </p>
                                                             </div>
                                                         </div>
                                                     </div>
                                            ))} */}
                                        {/* </div>
                                    </div> */}
                                        {/* <img src="/assets/images/Streaming_Platform_Logos/disney.jpg" alt="" /> */}
                                        {/* {(() => {
                                        if (watchData.length > 0 && watchData[0] != null && web.length != 0) {
                                            
                                            if ('flatrate' in watchData[0]) {
                                                var mayarray = [];
                                              mayarray.push(watchData[0].flatrate.forEach((element:any,index:number) => {
                                                    var providername = watchData[0].flatrate[index].provider_name;
                                                    const myprovidername = providername.split(" ");
                                                    if(myprovidername.length > 1){
                                                        var myString=myprovidername[0].toString();
                                                        if(myString == "Amazon"){
                                                            myString = "prime" ;
                                                        }
                                                        providername =  myString.toLowerCase();
                                                    }else{
                                                        providername = myprovidername[0].toLowerCase();
                                                    }
                                                     const watchprovidervalue =  web.us[providername];
                                                     return (
                                                        
                                                        <a  href={
                                                            web.us[providername][0].link
                                                            } target='_blank'>
                                                        <div className="movie-border row">
                                                            <div className="col-md-6 col-6">
                                                                <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].flatrate[index].logo_path}`} alt="" className='w-100' />
                                                            </div>
                                                            <div className="col-md-6 col-6 my-auto">
                                                                <p className="movie-rent mb-2">{watchData[0].flatrate[index].provider_name}</p>
                                                                <p className="movie-rent">Streaming Now</p>
                                                            </div>
                                                        </div>
                                                        </a>
                                                     )
                                                }));
                                                   
                                    

                                                return (
                                                    <div className="col-md-12 col-lg-4">
                                                    <h4 className="movie-overview">Where to Watch</h4>
                                                    {mayarray}
                                                </div>
                                                )
                                            }

                                        }

 
                                    })()} */}

                                        {/* {(() => {
                                        console.log(web.length)
                                        if (watchData.length > 0 && watchData[0] != null) {
                                            if ('buy' in watchData[0] && web?.length > 0) {



                                                var providername = watchData[0].buy[0].provider_name;
                                                const myprovidername = providername.split(" ");

                                                if (myprovidername.length > 1) {
                                                    var myString = myprovidername[0].toString();
                                                    if (myString == "Amazon") {
                                                        myString = "prime";
                                                    }
                                                    providername = myString.toLowerCase();
                                                } else {
                                                    providername = myprovidername[0].toLowerCase();
                                                }
                                                const watchprovidervalue = web.us[providername];




                                                return (
                                                    //     <div className="col-md-12 col-lg-4">
                                                    //     <h4 className="movie-overview">Buy</h4>
                                                    //     <div className="movie-border row">
                                                    //         <div className="col-md-5 col-5">
                                                    //             <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].buy[0].logo_path}`} alt="" className='w-100' />
                                                    //         </div>
                                                    //         <div className="col-md-7 col-7 my-auto">
                                                    //             <p className="movie-rent mb-2">{watchData[0].buy[0].provider_name}</p>
                                                    //             <p className="movie-rent">Buy Now</p>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>
                                                    <div className="col-md-12 col-lg-4">
                                                        <h4 className="movie-overview">Buy</h4>
                                                        <a href={(watchData[0].buy[0].provider_name == 'Apple TV') ? web.us.apple[0].link : null}>
                                                            <div className="movie-border row">
                                                                <div className="col-md-5 col-5">
                                                                    <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].buy[0].logo_path}`} alt="" className='w-100' />
                                                                </div>
                                                                <div className="col-md-7 col-7 my-auto">
                                                                    <p className="movie-rent mb-2">{watchData[0].buy[0].provider_name}</p>
                                                                    <p className="movie-rent">Buy Now</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            } else if ('rent' in watchData[0] && web?.length > 0) {

                                                for (let index = 0; index < watchData[0].rent.length; index++) {
                                                    const element = watchData[0].rent[index];


                                                    var providername = watchData[0].rent[index].provider_name;
                                                    const myprovidername = providername.split(" ");

                                                    if (myprovidername.length > 1) {
                                                        var myString = myprovidername[0].toString();
                                                        if (myString == "Amazon") {
                                                            myString = "prime";
                                                        }
                                                        providername = myString.toLowerCase();
                                                    } else {
                                                        providername = myprovidername[0].toLowerCase();
                                                    }
                                                    const watchprovidervalue = web.us[providername];

                                                    if (watchprovidervalue?.length > 0) {
                                                        return 0;
                                                    }

                                                    //  console.log(watchprovidervalue)
                                                }


                                                return (
                                                    <div className="col-md-12 col-lg-4">
                                                        <h4 className="movie-overview">Rent</h4>
                                                        <a href={web.us[providername][0].link} target='_blank'>
                                                            <div className="movie-border row">
                                                                <div className="col-md-5 col-5">
                                                                    <img src={`${process.env.REACT_APP_IMDB_IMAGE_URL}${watchData[0].rent[0].logo_path}`} alt="" className='w-100' />
                                                                </div>
                                                                <div className="col-md-7 col-7 my-auto">
                                                                    <p className="movie-rent mb-2">{watchData[0].rent[0].provider_name}</p>
                                                                    <p className="movie-rent">Buy Now</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            }

                                        }
                                    })()} */}
                                        {/* <div className="col-md-12 col-lg-4">
                                        <h4 className="movie-overview">Where to Watch</h4>
                                        <div className="movie-border row">
                                            <div className="col-md-6 col-6">
                                                <img src="/assets/images/Reel-Martini/img_detail_3.png" alt="" />
                                            </div>
                                            <div className="col-md-6 col-6 my-auto">
                                                <p className="movie-rent mb-2">Rent</p>
                                                <p className="movie-rent">$ 2.99 HD</p>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="col-md-12 col-lg-4">
                                            <h4 className="movie-overview">Rating</h4>
                                            <div className="movie-border row">
                                                <div className="col-md-6 col-6">
                                                    <img src="/assets/images/Reel-Martini/Img_IMDB.png" alt="" />
                                                </div>
                                                <div className="col-md-6 col-6 my-auto">
                                                    <p className="movie-rent mb-2">Rating</p>
                                                    <p className="movie-rent"><span>{detailData.vote_average}</span>/10</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 col-lg-4">
                                        <h4 className="movie-overview">Rating</h4>
                                        <div className="movie-border row">
                                            <div className="col-md-6 col-6">
                                                <img src="/assets/images/Reel-Martini/img_rotten.png" alt="" />
                                            </div>
                                            <div className="col-md-6 col-6 my-auto">
                                                <p className="movie-rent mb-2">Rating</p>
                                                <p className="movie-rent"><span>5.7</span>/10</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {(() => {
                    if ('reviews' in detailData) {
                        if (detailData.reviews.results.length > 0) {
                            return (
                                <section className="movie-slide5 movie-slide-mobile mt-5">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6  col-7">
                                                <h2 className="sectional-heading fs-20 section-name">Reviews</h2>
                                            </div>
                                            <div className="col-md-6  col-5  movie-headings">
                                                {/* <a href="#" className="sectional-heading fs-20 view-btn">View All <i className="fa fa-angle-right"
                                            aria-hidden="true"></i> </a> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                detailData && detailData.reviews && detailData.reviews.results.length > 0 &&
                                                detailData.reviews.results.map((item: any, index: number) => {
                                                    // let imgUrl = item.author_details.avatar_path.includes('http') ? item.author_details.avatar_path.substring(1) : `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.author_details.avatar_path}`;
                                                    let imgUrl = `${process.env.REACT_APP_IMDB_ORIGINAL_IMAGE_URL}${item.author_details.avatar_path}`;

                                                    return (
                                                        <div className="col-lg-6 col-md-6 col-12 pl-lg-0" key={`rewiew-${item.id}`}>
                                                            <div className="inner-padding">
                                                                <ReviewItem profileImage={imgUrl} name={item.author} username={item.author_details.username} description={item.content} date={item.created_at} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </section>
                            )
                        }
                    }

                })()}

                {(() => {
                    if ('similar' in detailData) {
                        if (detailData.similar.total_results > 0) {
                            return (
                                <section className="movie-slide1 movie-slide-mobile">
                                    <div className="container-fluid container-padding2">
                                        <div className="row">
                                            <ContentSlider title="Similar Movies" data={detailData && detailData.similar && detailData.similar.results} />
                                        </div>
                                    </div>
                                </section>
                            );
                        } else {
                            return (
                                <section className="movie-slide1 movie-slide-mobile">
                                    <div className="container-fluid p-2 container-padding2">
                                    </div>
                                </section>
                            )
                        }
                    }
                })()}
            </>}
        </>

    )
}
const mapStateToProps = (state: any) => {
    return {
        detailData: state.detailReducer.detailData,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchDetail: (id: any) => dispatch(fetchDetail(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
