export const HOME_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-home-data`;
export const DETAIL_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-movie-detail`;
export const MOVIE_LIST_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-movie-list`;
export const TV_LIST_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-tv-list`;
export const MOVIESDATA_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-movie-list`;
export const TV_DETAIL_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-tv-detail`;
export const QURATED_DATA_DETAIL_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-qrated-detail`;
export const NEWS_DATA_DETAIL_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-News-detail`;
export const QUARATED_CONTAINT_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-qrated-list`;
export const NEWS_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-News-list`;
export const FILTER_MOVIES_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-genre-data`;
export const FILTER_LANGUAGE_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-languages-data`;
export const LANGUAGE_API_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-filter-data`;
export const MOVIE_LIST_PAGE_API_URL_LANG_list = `${process.env.REACT_APP_API_URL}/get-filter-data`;

export const MOVIE_LIST_PAGE_API_URL_LANG = `${process.env.REACT_APP_API_URL}/get-movie-list-filtered`;
export const MOVIE_LIST_PAGE_API_URL_SORT = `${process.env.REACT_APP_API_URL}/get-movie-list-all`;

export const GET_MOVILE_WATCH_DETAIL = `${process.env.REACT_APP_API_URL}/getMovieWatchDetail`;

export const GET_TV_WATCH_DETAIL = `${process.env.REACT_APP_API_URL}/getTvWatchProvideDetail`;

export const GET_ALL_REVIEWS_MOVIES = `${process.env.REACT_APP_API_URL}/get_letest_review`;
export const GET_ALL_REVIEWS_TV = `${process.env.REACT_APP_API_URL}/get_tv_letest_review`;

export const ABOUT_US = `${process.env.REACT_APP_API_URL}/get-cmsdata`;

export const MOVIE_TRAILER = `${process.env.REACT_APP_API_URL}/videodetails`;   


// ===================================Tv list router Api=======================================

export const FILTER_TV_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-tvgenre-data`;
export const TV_DETAIL_FILTER_PAGE_API_URL = `${process.env.REACT_APP_API_URL}/get-filter-tv-data`;

export const Mail_for_contacts_API_URL = `${process.env.REACT_APP_API_URL}/Contact_support`;