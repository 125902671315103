import React from 'react';
import { BrowserRouter as Router, Route, HashRouter } from 'react-router-dom';
import Routes from './routes';

function App() {
  return (
    <HashRouter>
      <Routes/>
    </HashRouter>
  );
}

export default App;
