import React from 'react'
import MyComponent from './mycomponent';

const SkeletonDetails = () => {
    return (
        <section className="mx-4" id='skeletondetails'>

            <div className="container review-section ">
                <div className="section-padding">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 pl-lg-1">
                            <div className="movie-review-image">
                                <MyComponent height={508} width={355} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 my-auto">
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <h2 className="movie-name-review"><MyComponent height={27} width={340} /></h2>
                                </div>
                                <div className="col-md-12 col-lg-6 col-12">
                                    {/* <p className="movie-year">&nbsp;2022 - English - Action - 2h 11m</p> */}
                                </div>
                                <div className="col-md-12 col-lg-6 col-12">
                                    <p className="movie-realsedate">&nbsp;Release Date: <span> <MyComponent height={12} width={75} /></span>
                                    </p>
                                </div>
                                <div className="col-md-12 col-lg-6 col-12 my-auto">
                                    <a href='null'>
                                        <p className="movie-watchtrailer"><i className="fa fa-play"></i> &nbsp;Watch Trailer</p>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="movie-overview">Overview</h4>
                                    <p className="movie-overview-2"><MyComponent height={53} width={50} /></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <h4 className="movie-overview">Actor</h4>


                                    <p className="movie-overview-2"><MyComponent height={12} width={137} /><MyComponent height={12} width={137} /><MyComponent height={12} width={137} /></p>


                                    <h4 className="movie-overview"><a href="null" className='sectional-heading fs-12'>  Read more</a></h4>




                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <h4 className="movie-overview">Screenplay</h4>
                                    <p className="movie-overview-2"><MyComponent height={12} width={137} /><MyComponent height={12} width={137} /><MyComponent height={12} width={137} /></p>
                                </div>
                                <div className="col-lg-5 col-md-12">
                                    <h4 className="movie-overview">Director</h4>

                                    <p className="movie-overview-2"><MyComponent height={12} width={137} /><MyComponent height={12} width={137} /><MyComponent height={12} width={137} /></p>
                                </div>





                                <div className="col-md-12 col-lg-4">
                                    <h4 className="movie-overview">Where to Watch</h4>
                                    <a href='null'>
                                        <div className="movie-border row">
                                            <div className="col-md-6 col-6">
                                                <MyComponent height={61} width={61} />
                                            </div>
                                            <div className="col-md-6 col-6 my-auto">
                                                <p className="movie-rent mb-2"><MyComponent height={13} width={41} /></p>
                                                <p className="movie-rent">Streaming Now</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>


                                {/* <div className="col-md-12 col-lg-4">
                                            <h4 className="movie-overview">Buy</h4>
                                            <a>
                                                <div className="movie-border row">
                                                    <div className="col-md-5 col-5">
                                                    <MyComponent  height={71} width={71}/>
                                                    </div>
                                                    <div className="col-md-7 col-7 my-auto">
                                                        <p className="movie-rent mb-2"><MyComponent  height={13} width={41}/></p>
                                                        <p className="movie-rent">Buy Now</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div> */}



                                <div className="col-md-12 col-lg-4">
                                    <h4 className="movie-overview">Rating</h4>
                                    <div className="movie-border row">
                                        <div className="col-md-6 col-6">
                                            <MyComponent height={61} width={61} />
                                        </div>
                                        <div className="col-md-6 col-6 my-auto">
                                            <p className="movie-rent mb-2">Rating</p>
                                            <p className="movie-rent"> <MyComponent height={12} width={45} /></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-12 col-lg-4">
                            <h4 className="movie-overview">Rating</h4>
                            <div className="movie-border row">
                                <div className="col-md-6 col-6">
                                    <img src="/assets/images/Reel-Martini/img_rotten.png" alt="" />
                                </div>
                                <div className="col-md-6 col-6 my-auto">
                                    <p className="movie-rent mb-2">Rating</p>
                                    <p className="movie-rent"><span>5.7</span>/10</p>
                                </div>
                            </div>
                        </div> */}
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </section>
    )
}

export default SkeletonDetails
