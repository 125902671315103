import { MOVIESDATA_LIST_CONTENT, MOVIESDATA_LIST_LOAD_MORE } from "../constants";
const intialState = {
    listData : [],
    totalPage: 1,
    page: 1
};
export const moviesdataReducer = (state = intialState, action: any) => {
    switch (action.type) {
      case MOVIESDATA_LIST_CONTENT:
        return {
            ...state,
            page: 3,
            totalPage: action.data[2].total_pages,
            listData: action.data
        };;
      case MOVIESDATA_LIST_LOAD_MORE:
        var valur = action.data[0].page;
        console.log(valur);
        return {
            ...state,
            page: valur,
            listData:[...state.listData, ...action.data]
        };;
      default:
        return state;
    }
  };