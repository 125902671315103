import React, { useState } from 'react';
import { Mail_for_contacts_API_URL } from '../redux/action/apiConstants';

const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Form validation
    const errors: { [key: string]: string } = {};
    if (!name) {
      errors.name = 'Name is required';
    }
    if (!email) {
      errors.email = 'Email is required';
    }
    if (!message) {
      errors.message = 'Message is required';
    }

    // Display error messages if any
    setFormErrors(errors);

    // Submit the form if there are no errors
    if (Object.keys(errors).length === 0) {
      // Make a POST request to "/Contact_support" with the form data
      fetch(`${Mail_for_contacts_API_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      })
        .then(response => {
          // Handle the response as needed
          console.log(response);
          if (response.ok) {
            // Display success message
            setSuccessMessage('Email sent successfully');
            setTimeout(() => {
              window.location.href = 'https://reelmartinilive.sapphiresolutions.in.net/';
            }, 2000);

          } else {
            // Display error message
            setSuccessMessage('An error occurred. Please try again later.');
          }
        })
        .catch(error => {
          // Handle errors
          console.error(error);
          // Display error message
          setSuccessMessage('An error occurred. Please try again later.');
        });

      // Clear the form fields
      setName('');
      setEmail('');
      setMessage('');
      // Clear form errors
      setFormErrors({});
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">We want your input</h2>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <form onSubmit={handleSubmit}>                            
              <div className="form-group">
                  {/* <label htmlFor="message">Message:</label> */}
                  <textarea
                    id="message"
                    className="form-control"
                    value={message}
                    rows={7}
                    placeholder='Questions, bug reports, missing or wrong data, praise feature request - help us improve'
                    onChange={e => setMessage(e.target.value)}
                  ></textarea>
                  {formErrors.message && <p className="text-danger">{formErrors.message}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control border-bottom"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    style={
                      {border: 'none'}
                    }
                  />
                  {formErrors.name && <p className="text-danger">{formErrors.name}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control border-bottom"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={
                      {border: 'none'}
                    }
                  />
                  {formErrors.email && <p className="text-danger">{formErrors.email}</p>}
                </div>
                    
                <button type="submit" className="btn float-right text-white rounded-pill pr-3 pl-3"
                   style={
                    {borderRadius: '20px',
                      backgroundColor:"#1a2228"
                      }
                  }>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
