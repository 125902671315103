import React, { FC, useEffect, useState,useRef,ReactElement } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ABOUT_us } from '../redux/action/aboutAction';
import logo from './3819552.jpg';


type Props = {
    ABOUT_us ?: any
}

const Privacy_Policy:  FC<Props> = ({ ABOUT_us }) => {

    let { id } = useParams();
    const scollToRef = useRef();
    var actorsArray: any = [];
    var moreactorsArray: any = [];
    var screenplayArray: any = [];
    var directorArray: any = [];
    const [product,setProduct]= useState([])


    useEffect(() => {
    
        fetch(`https://reelmartiniliveapi.sapphiresolutions.in.net/api/get-cmsdata`)
        .then(res => res.json())
        .then(datas => {
          setProduct(datas.data)
            // console.log(product);
        })
    },[]);

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //   }, [])
    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0, 
    //         behavior: 'smooth'
    //       });// eslint-disable-next-line react-hooks/exhaustive-deps
    // },[ABOUT_us])


    return (
        <>
        <div className='container mt-3 mb-3 h-100 p-5'>
          
        
            {
         product.map((item:any , key:any) => {
            // console.log("jai")
             console.log(item);
            if (item._id == "63c77df406f1484ac4476959") {
                return(<section style={{color:'white'}}><h1>{item.cmsTitle}</h1><div dangerouslySetInnerHTML={{__html: item.cmsDescription}}></div></section>)
            }else{
                return(<section className='h-100 container aboutus'><h1></h1><div></div></section>)
            }
         })
        }
            

        </div>
        </>
    )
}

export default (Privacy_Policy);
