/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { Link } from "react-router-dom";
import ListItem from "./ListItem";
import NewsList from "./NewsListitem";
import QuratedList from "./QuratedListItem";


type Props = {
  title?: string;
  categoryId?: any;
  data?: [];
};

const ContentSlider: FC<Props> = ({ title, categoryId, data }) => {
  const isDataAvailable = data && data.length > 0;
  // console.log(title);
  return (
    <div
      className="MultiCarousel"
      id="MultiCarousel1"
      data-interval="1000"
    >
      {title === "Curated Content" ? (
        <Link
          className="sectional-heading fs-20 section-name float-left pt-2"
          to={`/curatedcontent/${categoryId}`}
        >
          {title}&nbsp; <i className="fa fa-angle-right"></i>
        </Link>
      ) : title === "News Content" ? (
        <Link
          className="sectional-heading fs-20 section-name float-left pt-2"
          to={`/news/${categoryId}`}
        >
          {"News"}&nbsp; <i className="fa fa-angle-right"></i>
        </Link>
      ) : title === "Similar Movies" ? (
        <div
          className="sectional-heading fs-20 section-name float-left pt-2"
        >
          {"Similar Shows"}&nbsp;
          {/* <i className="fa fa-angle-right"></i> */}
        </div>
      ) : (
        <Link
          className="sectional-heading fs-20 section-name float-left pt-2"
          to={`/movies/${categoryId}`}
        >
          {title}&nbsp; <i className="fa fa-angle-right"></i>
        </Link>
      )
      }
      {/* <a href="/list" className="sectional-heading fs-20 section-name float-left pt-2">{title}&nbsp; <i className="fa fa-angle-right"></i></a> */}


      {isDataAvailable && data!.length > 9 && (
        <>
          <a className="rightLst1 rigAr right-left next-full">
            <i className="fa fa-angle-right"></i>
          </a>
          <a className="leftLst1 leftAr right-left prev-full">
            <i className="fa fa-angle-left"></i>
          </a>
        </>
      )}
      <div className="MultiCarousel-inner owl-carousel owl-carousel0 owl-theme">
        {isDataAvailable ? (
          data!.map((item: any, index: number) => {
            return (
              <div className={`item`} key={`list-item-${index}`}>
                {title === "Curated Content" ? (
                  <QuratedList
                    mainId={item._id}
                    poster={`${process.env.REACT_APP_IMAGE_URL}/${item.image}`}
                    name={item.title}
                    description={item.description}
                  />
                ) : title === "News Content" ? (
                  <NewsList
                    mainId={item._id}
                    poster={`${process.env.REACT_APP_IMAGE_URL}/${item.newsimage}`}
                    name={item.newstitle}
                  />
                ) : (
                  <ListItem
                    mainId={item.id}
                    poster={`${process.env.REACT_APP_IMDB_IMAGE_URL}${item.poster_path}`}
                    name={item.title ? item.title : item.original_name}
                    id={item.title ? "movie" : "tv"}
                    description={
                      item.release_date
                        ? item.release_date
                        : item.first_air_date
                    }
                  />
                )}
              </div>
            );
          })
        ) : (
          <div className="no-data text-danger ml-3">No data available</div>
        )}
      </div>

      {/* <a className=" rightLst right-left next-single">
        <i className="fa fa-angle-right"></i>
      </a>
      <a className=" leftLst right-left prev-single">
        <i className="fa fa-angle-left"></i>
      </a> */}
      {isDataAvailable && data!.length > 9 && (
        <>
          <a className=" rightLst rightArr right-left next-single"  id="right_arrow">
            <i className="fa fa-angle-right"></i>
          </a>
          <a className=" leftLst leftArr right-left prev-single" id="left_arrow">
            <i className="fa fa-angle-left"></i>
          </a>
          {/* <a className="rightLst right-left next-single">
            <i className="fa fa-angle-right"></i>
          </a>
          <a className="leftLst right-left prev-single">
            <i className="fa fa-angle-left"></i>
          </a> */}
        </>
      )}
      <div className="sectional-padding"></div>
    </div>
  );
};

export default ContentSlider;
