import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import logo from './3819552.jpg'

type Props = {
    poster?: string,
    name?: string,
    description?: string,
    mainId?: any
    id?:any
}
const SimplePoster: FC<Props> = ({ mainId, poster, name, description, id}) => {
    console.log(id)
    return (
        // <div className="movie-review-card movie-car2 mt-3 ">
      
        <div className="review-card movie-car2">
                  {(()=>{
                if (id === 'Tv') {
                    return (
                <Link className="overlaylink" to={`/tvdetails/${mainId}`}></Link>
                    )
                }else{

                   return (
                <Link className="overlaylink" to={`/detail/${mainId}`}></Link>
                    )
                }
            })()}
            {/* <Link className="overlaylink" to={`/tvdetails/${mainId}`}></Link> */}
            {/* <a href="/list" className="overlaylink"></a> */}
            {poster !== undefined && poster.match("w200null") ? <img src={logo} alt="No imag" className="w-550 img-fluid"  /> :<img src={poster} alt=""
                className="w-550 img-fluid" /> }
            {/* <div className="on-hover">
                <div className="on-hover-absolute">
                    <h4>{name}</h4>
                    <h6>{description}</h6>
                    <p><a href="#"><i className="fa fa-plus mr-2"></i>Watchlist</a></p>
                </div>
            </div> */}
        </div>
    )
}


export default SimplePoster